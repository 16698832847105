//Font weight
$regular: normal;
$bold: bold;
$semibold: 600;
$medium: 500;
$font: 'Montserrat';

//Colors
$black: #2C3E50;
$black-secondary: #34495E;
$orange: #D35400;
$orange-hover: #B14600;
$blue: #2980B9;
$blue-hover: #226B9B;
$blue-secondary: #3498db;
$white: #FFF;
$white-secondary: #F5F6FA;
$white-tertiary: #E4E7EB;
$green: #27AE60;
$red: #C0392B;
$grey: #7F8C8D;

a {
	font-family: $font;
	font-weight: $medium;

	&:hover {
		text-decoration: none;
	}

	&.bold {
		font-weight: $bold;
	}

	&.link-inverse {
		color: $white;
		font-size: 18px;
		transition: 0.2s;

		&.fs24 {
			font-size: 24px;
		}

		&:hover {
			color: $grey;
			transition: 0.2s;
		}
	}
}

h1 {
	font-family: $font;
	font-style: normal;
	font-weight: $bold;
	font-size: 64px;
	line-height: 78px;
	color: $black;

	@media (max-width: 1366px) {
		font-size: 38px;
		line-height: 42px;
	}

	@media (max-width: 991px) {
		font-size: 32px;
		line-height: 42px;
	}
}

h2 {
	font-family: $font;
	font-style: normal;
	font-weight: $medium;
	font-size: 36px;
	line-height: 44px;
	color: $black;
	margin-bottom: 20px;

	@media (max-width: 1366px) {
		font-size: 30px;
		line-height: 36px;
	}

	@media (max-width: 991px) {
		font-size: 24px;
		line-height: 36px;
	}
}

p {
	font-family: $font;
	font-style: normal;
	font-weight: $medium;
	line-height: 29px;
	color: $black-secondary;

	&.fs24 {
		font-size: 24px;

		@media (max-width: 1366px) {
			font-size: 22px;
			line-height: 32px;
		}

		@media (max-width: 991px) {
			font-size: 18px;
			line-height: 32px;
		}
	}

	&.fs18 {
		font-size: 18px;

		@media (max-width: 991px) {
			font-size: 16px;
			line-height: 26px;
		}
	}

	&.fs16 {
		font-size: 16px;

		@media (max-width: 991px) {
			font-size: 14px;
			line-height: 22px;
		}
	}

	&.fs14 {
		font-size: 14px;

		@media (max-width: 991px) {
			line-height: 20px;
		}
	}

	&.grey {
		color: $grey;
	}

	&.blue {
		color: $blue;
	}
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

li {
	margin: 0;
	padding: 0;
}

.btn {
	border: none;
	border-radius: 5px;
	padding: 17px 55px;

	@media (max-width: 991px) {
		display: block;
		width: 100%;
	}
}

.btn-warning {
	background: $orange;
	color: $white;
	font-family: $font;
	font-style: normal;
	font-weight: $bold;
	font-size: 18px;
	line-height: 22px;

	&:hover {
		color: $white;
		background: $orange-hover;
	}

	&:active {
		color: $white !important;
		background-color: $orange-hover !important;
    	border-color: $orange-hover !important;
	}
}

.btn-primary {
	background: $blue;
	color: $white;
	font-family: $font;
	font-style: normal;
	font-weight: $bold;
	font-size: 18px;
	line-height: 22px;

	&:hover {
		color: $white;
		background: $blue-hover;
	}

	&:active {
		background-color: $blue-hover !important;
    	border-color: $blue-hover !important;
	}
}

.label {
	font-family: $font;
	font-style: normal;
	font-weight: $medium;
	font-size: 24px;
	line-height: 29px;
	color: $blue;
	margin-bottom: 16px;

	@media (max-width: 991px) {
		font-size: 16px;
		margin-bottom: 6px;
	}

	&.warning {
		color: $orange;
	}
}

.bold {
	font-weight: $bold;
}

.discount {
	font-family: $font;
	font-style: normal;
	font-weight: $medium;
	font-size: 18px;
	line-height: 22px;
	text-decoration-line: line-through;
	color: $red;
}

.container {

	@media (min-width: 1200px){
	    max-width: 1480px;
	}
}

.p-row {
	padding-top: 48px;
	padding-bottom: 48px;

	@media (max-width: 991px){
	    padding-top: 26px;
		padding-bottom: 26px;
	}
}

.img-center {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;

	img {
		width: 100%;
	}
}

.ic-row {

	.ic-row__content {
		padding-top: 56px;

		@media (max-width: 991px){
		    padding-top: 0;
		    margin: 0 auto;
		}
	}

	.ic-row__img {

		@media (max-width: 991px){
		    display: none;
		}
	}
}

input{

	&.form-control {
		padding-left: 18px;
		padding-right: 18px;
		background: inherit;
		border: 1px solid #BDC3C7;
		height: 58px;
		color: $grey;
		font-family: $font;
		font-style: normal;
		font-weight: $medium;
		font-size: 16px;

		&:focus {
			outline: none !important;
			box-shadow: none !important;
			border: 1px solid $blue;
			background: inherit;
		}
	}
}

textarea {

	&.form-control {
		padding: 18px;
		background: inherit;
		border: 1px solid #BDC3C7;
		color: $grey;
		font-family: $font;
		font-style: normal;
		font-weight: $medium;
		font-size: 16px;
		resize: none;

		&:focus {
			outline: none !important;
			box-shadow: none !important;
			border: 1px solid $blue;
			background: inherit;
		}
	}
}

.bg-shadow {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 98;
	background: #000;
	opacity: 0.8;
	display: none;
}