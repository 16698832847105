body {
	background-size: contain;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: url("../img/default/bg-header.png") top no-repeat;
    background-size: contain;
    height: 100%;
    width: 100%;
}

header {
	padding: 18px 0;

	@media (max-width: 991px) {
		padding: 8px 0;
		width: 100%;
		position: fixed;
		z-index: 99;
		transition: 0.1s;
		top: 0;

		&.scroll {
			transition: 0.1s;
			background: #fff;
			-webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.25);
			-moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.25);
			box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.25);
		}

		&.closed {
			top: -52px;
		}
	}

	.header__content {
		display: flex;
		justify-content: space-between;

		img {
			height: 46px;
			width: auto;
			display: flex;
    		align-self: center;

    		@media (max-width: 991px) {
				height: 36px;
				display: block;
    			margin: 0 auto;
			}
		}

		.header__content-menu {

			@media (max-width: 991px) {
				position: fixed;
				top: 0;
				right: -300px;
				width: 300px;
				min-height: 100vh;
				height: 100%;
				background: #fff;
				z-index: 99;
				padding: 15px;
				padding-top: 45px;
				display: block;
				transition: 0.2s;

				&.open {
					-webkit-box-shadow: -5px 0px 16px -2px rgba(0,0,0,0.22);
					-moz-box-shadow: -5px 0px 16px -2px rgba(0,0,0,0.22);
					box-shadow: -5px 0px 16px -2px rgba(0,0,0,0.22);
					right: 0;
					transition: 0.2s;
				}
			}

			.btn-close {
				display: none;

				@media (max-width: 991px) {
					display: block;
					position: absolute;
					top: 15px;
					right: 15px;
				}

				i {
					color: $black;
					font-size: 26px;
				}
			}

			ul {

				@media (max-width: 991px) {
					height: 100%;
					position: relative;
				}

				li {
					display: inline-block;
					padding: 0 15px;

					@media (max-width: 991px) {
						display: flex;
						width: 100%;
						height: 55px;
						justify-content: flex-end;
						align-items: center;
					}

					a.text {
						display: block;
						padding: 15px 0;
						font-family: $font;
						font-style: normal;
						font-weight: $medium;
						font-size: 18px;
						line-height: 22px;
						color: $black;
						border-bottom: 3px solid inherit;

						@media (max-width: 991px) {
							border-bottom: 3px solid $white;
							display: initial;
						}

						&:hover {
							border-bottom: 3px solid $black;
						}

						&.active {
							border-bottom: 3px solid $black;
						}
					}
				}
				a.btn {
					@media (max-width: 991px) {
						display: block;
						width: 100%;
						margin-top: 16px;
						position: absolute;
						bottom: 0;
					}
				}
			}
		}

		.btn-menu {
			position: absolute;
			top: 13px;
			right: 15px;
			display: none;

			@media (max-width: 991px) {
				display: block;
			}

			i {
				font-size: 26px;
				color: $black;
			}
		}
	}
}

#intro {
	padding-top: 209px;

	@media (max-width: 991px) {
		padding-top: 80px;
		padding-bottom: 80px;
		text-align: center;
	}

	.intro__content {
		max-width: 615px;

		@media (max-width: 991px) {
			margin: 0 auto;
		}

		h1 {
			margin-bottom: 18px;
		}
		
		p {
			margin-bottom: 62px;

			@media (max-width: 991px) {
				margin-bottom: 32px;
			}
		}
		
	}

	.intro__img {

		@media (max-width: 991px) {
			display: none;
		}

		img {
			max-width: 100%;
		}
	}

	.softwares {
		padding-top: 42px;
		display: flex;
		justify-content: flex-end;

		@media (max-width: 991px) {
			justify-content: space-around;
		}

		img {
			max-height: 58px;
			width: auto;

			@media (max-width: 991px) {
				max-height: 44px;
			}
		}
	}
}

#venda-site {
	background: url("../img/default/bg-venda-site.png") center no-repeat;
	background-size: cover;

	.venda-site__title {
		text-align: center;

		h2 {
			margin-bottom: 46px;
		}
	}

	.col-md-6 {
		@media (max-width: 991px) {
			margin-bottom: 20px;
		}
	}

	.venda-site__card {
		background: #FFFFFF;
		box-shadow: 5px 5px 10px rgba(211, 84, 0, 0.1);
		border-radius: 5px;
		padding: 24px;
		max-width: 338px;
		min-height: 434px;
		width: 100%;
		height: 100%;
		position: relative;

		@media (max-width: 991px) {
			margin: 0 auto;
			min-height: 374px;

			&.float-right {
				float: none !important;
			}
		}
		
		p.bold {
			text-align: center;
			margin-bottom: 24px;
		}

		ul {
			margin-bottom: 24px;

			p {
				margin-bottom: 6px;

				i {
					color: $green;
				}
			}
		}

		.price {
			font-family: $font;
			display: flex;
			align-items: center;
			justify-content: space-between;
			max-width: 246px;
			margin: 0 auto;
			position: relative;
			padding-top: 10px;

			.promo {
				position: absolute;
				top: 0;
				width: 100%;
				text-align: center;

				.discount {
					text-align: right;
				}
			}

			.value {
				font-style: normal;
				font-weight: $bold;
				font-size: 72px;
				line-height: 88px;
				color: $orange;
			}

			.month {
				font-weight: $medium;
				font-size: 18px;
				line-height: 22px;
				color: $orange;
			}
		}

		.btn-warning {
			display: block;
			max-width: 214px;
			margin: 0 auto;
		}
	}
}

#servicos {
	
	.servicos__title {
		max-width: 763px;
		padding-bottom: 60px;
	}

	.col-md-6 {
		@media (max-width: 1140px) {
			margin-bottom: 20px;
		}
	}

	.servicos__card {
		background: $white-secondary;
		border-radius: 10px;
		padding: 40px;
		max-width: 340px;
		margin: 0 auto;
		height: 100%;
		display: block;
		position: relative;
		padding-bottom: 63px;

		@media (max-width: 991px) {
			max-width: 100%;
		}

		.circle {
			width: 60px;
			height: 60px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: $white-tertiary;
			border-radius: 50%;
			margin-bottom: 22px;

			i {
				font-size: 24px;
				color: $blue;

				&.fa-copyright {
					font-size: 30px;
				}

				&.fa-slack-hash {
					font-size: 30px;
				}
			}
		}

		.blue {
			margin-bottom: 0;
			position: absolute;
			bottom: 40px;
			left: 40px;
		}

		&:hover {
			background: rgb(52,152,219);
			background: -moz-linear-gradient(97deg, rgba(52,152,219,1) 0%, rgba(41,128,185,1) 100%);
			background: -webkit-linear-gradient(97deg, rgba(52,152,219,1) 0%, rgba(41,128,185,1) 100%);
			background: linear-gradient(97deg, rgba(52,152,219,1) 0%, rgba(41,128,185,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3498db",endColorstr="#2980b9",GradientType=1);
			cursor: pointer;
			box-shadow: 5px 5px 10px rgba(41, 128, 185, 0.4);

			.circle {
				background: $blue-secondary;

				i {
					color: $white;
				}
			}

			p {
				color: $white;
			}
		}
	}
}

#contato {
	background: url("../img/default/contato-bg.png") bottom no-repeat;
	background-size: contain;
	padding-top: 134px;
	padding-bottom: 100px;

	@media (max-width: 991px){
	    padding-top: 0;
	}

	@media (max-width: 768px){
		.container-fluid {
		    max-width: 540px;
		}
	}

	.ic-row__content {
		max-width: 696px;
		width: 100%;

		form {

			.nome-email {
				display: flex;
				justify-content: space-between;
				margin-bottom: 26px;

				input.nome {
					max-width: 250px;
					margin-right: 26px;
				}
			}

			textarea {
				margin-bottom: 26px;
			}

			.btn-primary {
				display: block;
				width: 100%;
				max-width: 251px;

				@media (max-width: 991px){
				    max-width: 100%;
				}
			}
		}
	}

}

footer {
	padding-top: 138px;
	background: $black-secondary;

	@media (max-width: 576px) {
		padding-top: 26px;
	}

	.col-sm-6 {
		margin-bottom: 26px;
	}

	.footer__contact {

		@media (max-width: 576px) {
			text-align: center;
		}

		img {
			display: block;
			max-width: 214px;
			margin-bottom: 51px;

			@media (max-width: 576px) {
				margin: 0 auto;
				margin-bottom: 26px;
			}
		}

		a {
			display: block;

			&.fs24 {
				margin-bottom: 8px;
			}
		}

		.social-media {
			display: flex;
			width: 120px;
			justify-content: space-between;
			padding-top: 58px;

			@media (max-width: 576px) {
				padding-top: 0px;
				margin: 0 auto;
				margin-bottom: 36px;
				width: 150px;
			}

			a {
				i {
					color: $white;
					font-size: 24px;
					transition: 0.2s;

					@media (max-width: 576px) {
						font-size: 36px;
					}
				}

				&:hover {

					i {
						color: $grey;
						transition: 0.2s;
					}
				}
			}
		}
	}

	.footer_title {
		font-weight: bold;
		font-size: 22px;
		line-height: 27px;
		color: #FFFFFF;
		margin-bottom: 33px;
	}

	.link-inverse {
		display: block;
		margin-bottom: 25px;
		font-weight: $regular;
	}

	.footer_item {
		font-weight: $regular;
		font-size: 18px;
		line-height: 22px;
		color: #FFFFFF;
		margin-bottom: 25px;

		i {
			font-size: 22px;
			margin-right: 10px;
		}
	}

	form {
		display: block;
		width: 100%;
		position: relative;

		.form-control {
			width: 100%;
			height: 58px;
			background: #435667;
			color: #fff;
			border: none;
			border-radius: 5px;
			transition: 0.2s;
			padding-right: 58px;
			margin-bottom: 25px;

			&:focus {
				border: #fff 2px solid;
				transition: 0.2s;
				color: #fff;
			}
		}

		.btn {
			position: absolute;
			right: 20px;
			top: 15px;
			padding: 0;

			@media (max-width: 991px){
			    width: 30px;
			}

			i {
				color: #fff;
				font-size: 26px;
				transition: 0.2s;

				&:hover {
					color: $grey;
					transition: 0.2s;
				}
			}
		}
	}

	.footer_copy {
		font-weight: $regular;
		font-size: 16px;
		line-height: 17px;
		color: #FFFFFF;
		border-top: 2px solid #fff;
		text-align: center;
		padding: 36px 0;
		margin-top: 69px;

		@media (max-width: 576px) {
			margin-top: 0px;
		}
	}
}

#login {
	min-height: 100vh;
	height: 100%;
	display: flex;
	align-items: center;

	.login-form {
		margin: 0 auto;
		background: $white-secondary;
		border-radius: 20px;
		max-width: 549px;
		width: 100%;
		height: 100%;
		padding: 25px 90px;
		text-align: center;
		position: relative;

		@media (max-width: 768px) {
			padding: 15px 15px;
		}

		img {
			display: block;
			margin: 0 auto;
			margin-bottom: 25px;
			height: 46px;
			width: auto;
		}

		form {

			.login-form__input {
				position: relative;
				margin-bottom: 22px;
				transition: 0.1s;

				&.focus {
					transition: 0.1s;

					.form-control {
						border: 3px solid $blue;
						transition: 0.1s;
					}

					label {
						transition: 0.1s;
						color: $blue;
					    top: -9px;
					    left: 20px;
					    font-weight: $semibold;
					    font-size: 12px;
					    line-height: 22px;
					    padding-left: 5px;
					    padding-right: 5px;
					}
				}

				&.fill {
					transition: 0.1s;

					.form-control {
						border: 3px solid $black;
						transition: 0.1s;
					}

					label {
						transition: 0.1s;
						color: $black;
					}
				}

				.form-control {
					transition: 0.1s;
					border: 3px solid $black;
					color: $black;
					border-radius: 5px;
					width: 100%;
					padding-left: 20px;
					padding-right: 20px;
				}

				label {
					transition: 0.1s;
					pointer-events: none;
					font-size: 18px;
					line-height: 22px;
					color: $black;
					background: $white-secondary;
					display: block;
					position: absolute;
					top: 16px;
					left: 20px;
				}
			}

			.btn-primary {
				width: 100%;
				background: $black;
				margin-top: 14px;
				margin-bottom: 23px;

				&:hover {
					background: $black-secondary;
				}
			}
		}

		.forgot {
			display: block;
			margin-bottom: 10px;
		}
	}
}